.scrolling {
  .header {
    height: 60px;
    background: #fff;

    @media (max-width: $mobile) {
      height: 50px;
    }

    .wrapper {
      height: 60px;

      @media (max-width: $mobile) {
        height: 50px;
      }
    }
  }

  .content {
    padding-top: 60px;

    @media (max-width: $mobile) {
      padding-top: 50px;
    }
  }
}

.title {
  display: flex;
  justify-content: center;
  font-style: normal;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #101828;
  font-weight: 600;
  margin-bottom: 16px;

  @media (max-width: $mobile) {
    font-size: 36px;
    line-height: 40px;
  }
}

.description {
  display: flex;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #2B3647;
  max-width: 500px;
  margin: 0 auto;

  @media (max-width: $mobile) {
    font-size: 18px;
    line-height: 28px;
  }
}

.content {
  padding-top: 80px;
  transition: all 600ms ease;

  @media (max-width: $tablet) {
    padding-top: 72px;
  }
}

.main-section {
  position: relative;
  padding: 96px 0;

  @media (max-width: $tablet) {
    padding-bottom: 64px;
  }

  @media (max-width: $mobile) {
    padding: 80px 0 54px;
  }

  .main-logo-img {
    display: flex;
    justify-content: center;
    width: 560px;
    height: 86px;
    /*background: url(../img/u-logo.svg) 0 0 no-repeat;
    background-size: 100% auto;*/
    margin: 0 auto 40px;

    @media (max-width: $mobile) {
      width: 312px;
      height: 48px;
      margin-bottom: 32px;
    }

    svg,
    img {
      width: 100%;
      height: 100%;
    }
  }

  .description {
    max-width: 762px;
    margin-bottom: 48px;

    @media (max-width: $tablet) {
      max-width: 700px;
    }

  }

  .f_button {
    display: flex;
    margin: 0 auto;
    max-width: 272px;
  }
}

.who-we-are-section {
  position: relative;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 16px 16px 0px 0px;
  padding: 96px 0 63px;
  overflow: hidden;

  @media (max-width: $tablet) {
    background: none;
    padding-top: 64px;
  }

  @media (max-width: $mobile) {
    padding: 54px 0 40px;
    overflow: visible;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    content: "";
    background: url(../img/u-safari-bg.svg) 0 0 no-repeat;
    height: 32px;

    @media (max-width: $tablet) {
      display: none;
    }
  }

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    height: 1px;
    background: linear-gradient(270deg, #CB77FF 0%, #1D70F0 100%);

    @media (max-width: $tablet) {
      left: 40px;
      right: 40px;
    }

    @media (max-width: $mobile) {
      left: 0;
      right: 0;
    }
  }

  .description {
    max-width: 578px;
    margin-bottom: 64px;
    justify-content: center;

    @media (max-width: $mobile) {
      margin-bottom: 48px;
    }
  }
  
  .code-block {
    position: relative;
    background: #232C42;
    border-radius: 8px;
    width: 752px;
    margin: 0 auto 64px;
    padding-left: 61px;
    box-shadow: 0px 1px 2px -1px rgba(35, 44, 66, 0.2), 0px 40px 40px -26px rgba(35, 44, 66, 0.5);
    overflow: hidden;
    min-height: 440px;

    @media (max-width: $tablet) {
      padding-left: 56px;
      width: 696px;
      min-height: 407px;
    }

    @media (max-width: $mobile) {
      padding-left: 26px;
      min-height: 191px;
      margin-bottom: 40px;
      width: 328px;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      content: "";
      background: url(../img/u-code-sidebar.svg) 0 0 no-repeat;
      width: 61px;

      @media (max-width: $tablet) {
        background-size: 100% auto;
        width: 56px;
      }

      @media (max-width: $mobile) {
        width: 26px;
      }
    }

    pre {
      padding: 21px 24px 2px 13px;
      font-family: $font-roboto-mono;
      font-weight: 500;
      font-size: 14.3286px;
      line-height: 19px;
      margin: 0;

      @media (max-width: $tablet) {
        padding: 19px 20px 2px 11px;
        font-size: 13.26px;
        line-height: 17.49px;
      }

      @media (max-width: $mobile) {
        font-size: 6.25px;
        line-height: 8.24px;
        padding: 9px 10px 2px 5px;
      }

      &:after{
        content: "|";
        animation: blink 500ms linear infinite alternate;
        color: #fff;
      }

      @keyframes blink{
        0%{opacity: 0;}
        100%{opacity: 1;}
      }
    }

    b {
      font-weight: inherit;
    }

    .comments {
      color: #74985A;
    }

    .blue {
      color: #699CD3;
    }

    .yellow {
      color: #F7D73B;
    }

    .lblue {
      color: #ABDBFC;
    }

    .pink {
      color: #CC73D3;
    }

    .white {
      color: #fff;
    }
  }

  .semi-description {
    display: flex;
    justify-content: center;
    max-width: 559px;
    font-size: 16px;
    line-height: 28px;
    color: #2B3647;
    margin: 0 auto 64px;
    text-align: center;

    @media (max-width: $mobile) {
      margin-bottom: 40px;
    }
  }

  .quote-block {
    position: relative;
    display: flex;
    max-width: 735px;
    margin: 0 auto;
    font-size: 28px;
    line-height: 40px;
    color: #232C42;
    padding: 0 30px;

    @media (max-width: $tablet) {
      font-size: 26px;
      line-height: 40px;
    }

    @media (max-width: $mobile) {
      line-height: 36px;
      justify-content: center;
      text-align: center;
      padding: 0 10px;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 24px;
      height: 24px;
      background: url(../img/u-quote-icon-top.svg) 50% 50% no-repeat;

      @media (max-width: $mobile) {
        left: -10px;
      }
    }

    &:after {
      position: absolute;
      bottom: 10px;
      right: 10px;
      content: '';
      width: 24px;
      height: 24px;
      background: url(../img/u-quote-icon-b.svg) 50% 50% no-repeat;

      @media (max-width: $tablet) {
        right: 30px;
      }

      @media (max-width: $mobile) {
        right: -10px;
      }
    }
  }
}

.our-experience-section {
  position: relative;
  padding: 64px 0;
  max-width: 944px;
  margin: 0 auto;
  text-align: center;

  @media (max-width: $mobile) {
    padding: 40px 0;
  }

  &:after {
    position: absolute;
    bottom: 0;
    left: 40px;
    right: 40px;
    content: "";
    height: 1px;
    background: linear-gradient(270deg, #CB77FF 0%, #1D70F0 100%);
    display: none;

    @media (max-width: $tablet) {
      display: block;
    }

    @media (max-width: $mobile) {
      left: 0;
      right: 0;
    }
  }

  .description {
    max-width: 752px;
  }

  .wrapper {
    display: flex;
    margin: 72px 0 0;
    justify-content: space-between;

    @media (max-width: $tablet) {
      margin-top: 40px;
      display: block;
    }

    .item {
      flex: 0 auto;
      width: auto;

      @media (max-width: $tablet) {
        width: 100%;
        margin-top: 64px;
      }

      &:first-child {
        @media (max-width: $tablet) {
          margin-top: 0;
        }
      }

      .img-block {
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 128px;
          height: 128px;
        }
      }

      .item-title {
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        color: #101828;
      }
    }
  }
}

.services-section {
  position: relative;
  padding: 64px 0;

  &:after {
    position: absolute;
    bottom: 0;
    left: 40px;
    right: 40px;
    content: "";
    height: 1px;
    background: linear-gradient(270deg, #CB77FF 0%, #1D70F0 100%);
    display: none;

    @media (max-width: $tablet) {
      display: block;
    }

    @media (max-width: $mobile) {
      left: 0;
      right: 0;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    margin: 64px -8px 0;

    @media (max-width: $tablet) {
      margin: -8px;
      margin-top: 64px;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 736px;
      margin: 64px auto -8px;
    }

    .item {
      flex: 0 calc((100% / 6) - 16px);
      width: calc((100% / 6) - 16px);
      margin: 0 8px;
      background: #FFFFFF;
      border: 1px solid #E6E9ED;
      border-radius: 8px;
      min-height: 192px;
      padding: 24px;

      @media (max-width: $tablet) {
        flex: 0 176px;
        width: 176px;
        margin: 8px;
      }

      @media (max-width: $mobile) {
        flex: 0 calc(50% - 16px);
        width: calc(50% - 16px);
        max-width: 156px;
      }

      .img-block {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;

        @media (max-width: $mobile) {
          margin-bottom: 22px;
        }
      }

      .item-title {
        display: block;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #2B3647;
      }
    }
  }
}

.process-section {
  position: relative;
  padding: 64px 96px;
  text-align: center;
  background: #fff;

  @media (max-width: $tablet) {
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    background: none;
  }

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    height: 1px;
    background: linear-gradient(270deg, #CB77FF 0%, #1D70F0 100%);

    @media (max-width: $tablet) {
      left: 40px;
      right: 40px;
    }

    @media (max-width: $mobile) {
      left: 0;
      right: 0;
    }
  }

  .description {
    max-width: 752px;
    margin-bottom: 72px;

    @media (max-width: $mobile) {
      margin-bottom: 40px;
    }
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -36px 0;

    @media (max-width: $tablet) {
      margin: -28px;
    }

    @media (max-width: $mobile) {
      display: block;
      margin: 0;
    }

    .item {
      flex: 0 368px;
      width: 368px;
      margin: 32px 0;
      text-align: left;

      @media (max-width: $tablet) {
        flex: 0 calc(50% - 56px);
        width: calc(50% - 56px);
        margin: 28px;
      }

      @media (max-width: $mobile) {
        width: 100%;
        margin: 48px 0 0;
      }

      &:first-child {
        @media (max-width: $mobile) {
          margin-top: 0;
        }
      }

      .img-block {
        display: flex;
        margin-bottom: 16px;

        img {
          width: 56px;
          height: 56px;
        }
      }

      .item-title {
        display: flex;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        margin-bottom: 16px;
      }

      p {
        margin: 0;
        color: #2B3647;
        line-height: 24px;
        font-size: 16px;
      }
    }
  }
}

.technologies {
  position: relative;
  padding: 64px 0;
  text-align: center;
  overflow: hidden;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    height: 1px;
    background: linear-gradient(270deg, #CB77FF 0%, #1D70F0 100%);

    @media (max-width: $tablet) {
      left: 40px;
      right: 40px;
    }

    @media (max-width: $mobile) {
      left: 0;
      right: 0;
    }
  }

  .description {
    max-width: 752px;
    margin-bottom: 64px;

    @media (max-width: $mobile) {
      margin-bottom: 48px;
    }
  }

  .blockchain-block {
    .block-title {
      display: flex;
      font-weight: 500;
      font-size: 28px;
      line-height: 40px;
      color: #101828;
      justify-content: center;
      margin-bottom: 64px;

      @media (max-width: $tablet) {
        margin-bottom: 48px;
      }

      @media (max-width: $mobile) {
        margin-bottom: 40px;
      }
    }
    .left-block {
      width: 100%;

      @media (max-width: $tablet) {
        width: 100%;
        margin: 0;
      }

      .left-block-wrapper {
        display: flex;
        flex-wrap: wrap;
        text-align: left;
        align-items: flex-start;
        justify-content: center;
        max-width: 944px;
        margin: 0 auto;

        @media (max-width: $tablet) {
          margin: 0;
          flex-direction: column;
          flex-wrap: nowrap;
          align-items: center;
        }
      }

      .item {
        flex: 0 270px;
        width: 270px;
        margin-left: 114px;

        @media (max-width: $tablet) {
          flex: 0 auto;
          width: 340px;
          margin: 48px auto 0;
          text-align: center;
          order: 3;
        }

        @media (max-width: $mobile) {
          margin-top: 40px;
        }

        &.crypto-item {
          margin: 0;
          flex: 0 540px;
          width: 540px;

          @media (max-width: $tablet) {
            flex: 0 auto;
            margin: 0;
            order: 2;
          }
        }

        &:first-child {
          flex: 0 540px;
          width: 540px;
          padding-left: 94px;
          margin: 0 0 20px;

          @media (max-width: $tablet) {
            margin-top: 0;
            flex: 0 auto;
            width: 340px;
            padding: 0;
            order: 1;
            margin-bottom: 48px;
          }

          @media (max-width: $mobile) {
            margin-bottom: 40px;
          }
        }

        &:last-child {
          margin-top: 64px;
        }

        .item-title {
          display: flex;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: #000000;
          margin-bottom: 16px;

          @media (max-width: $tablet) {
            justify-content: center;
          }

          @media (max-width: $mobile) {
            margin-bottom: 12px;
          }
        }

        p {
          margin: 0;
          line-height: 24px;
          color: #2B3647;
          font-size: 16px;
        }
      }
    }

    .crypto-block {
      background: #FFFFFF;
      box-shadow: 0px 1px 2px -1px rgba(35, 44, 66, 0.2), 0px 40px 40px -26px rgba(35, 44, 66, 0.12);
      border-radius: 8px;
      padding: 32px;
      width: 540px;
      margin: 0 auto 64px;

      @media (max-width: $tablet) {
        margin-bottom: 0;
      }

      @media (max-width: $mobile) {
        width: 100%;
        max-width: 430px;
        padding: 16px;
      }

      .inner-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        margin-top: 40px;

        @media (max-width: $mobile) {
          margin-top: 16px;
        }

        &:first-child {
          margin-top: 0;
        }

        .item {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #000000;

          @media (max-width: $mobile) {
            font-size: 10px;
            line-height: 14px;
          }

          &:first-child {
            flex: 0 1 125px;
            width: 125px;
          }

          &:last-child {
            position: relative;
            padding-left: 20px;

            &:before {
              position: absolute;
              top: 50%;
              left: 0;
              content: "";
            }

            &.up {
              color: #06AA48;

              &:before {
                background: url(../img/u-icon-up.svg) 0 0 no-repeat;
                width: 12px;
                height: 12px;
                margin-top: -6px;

                @media (max-width: $mobile) {
                  width: 6px;
                  height: 6px;
                  margin-top: -3px;
                  background-size: 100% auto;
                }
              }
            }

            &.down {
              color: #FC3E3E;

              &:before {
                background: url(../img/u-icon-down.svg) 0 0 no-repeat;
                width: 12px;
                height: 12px;
                margin-top: -6px;

                @media (max-width: $mobile) {
                  width: 6px;
                  height: 6px;
                  margin-top: -3px;
                  background-size: 100% auto;
                }
              }
            }
          }

          .icon {
            flex: 1 0 44px;
            width: 44px;
            margin-right: 16px;
            display: flex;
            max-width: 44px;

            @media (max-width: $mobile) {
              flex: 1 0 26px;
              width: 26px;
              max-width: 26px;
              margin-right: 8px;
            }
          }

          .name {
            display: block;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #000000;

            @media (max-width: $mobile) {
              font-size: 10px;
              line-height: 14px;
            }

            b {
              display: block;
              font-size: 14px;
              font-weight: 400;
              color: #667085;

              @media (max-width: $mobile) {
                font-size: 8px;
                line-height: 12px;
                margin-top: 2px;
              }
            }
          }
        }
      }
    }

    .bottom-block {
      margin-top: 64px;

      @media (max-width: $mobile) {
        margin-top: 56px;
      }

      .block-title {
        margin-bottom: 16px;
      }

      .block-description {
        display: flex;
        max-width: 548px;
        margin: 0 auto;
      }
    }
  }
}

.demo-section {
  position: relative;
  padding: 64px 0 96px;
  text-align: center;

  @media (max-width: $mobile) {
    padding: 54px 0;
  }

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    height: 1px;
    background: linear-gradient(270deg, #CB77FF 0%, #1D70F0 100%);
  }

  .description {
    max-width: 515px;
    margin-bottom: 36px;
  }

  .f_button {
    margin: 0 auto 64px;
  }

  .img-block {
    display: flex;
    box-shadow: 0px 1px 2px -1px rgba(35, 44, 66, 0.2), 0px 40px 40px -26px rgba(35, 44, 66, 0.12);
    border-radius: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.get-touch-section {
  padding: 65px 0 96px;

  @media (max-width: $mobile) {
    padding: 54px 0 64px;
  }

  .form-block {
    max-width: 368px;
    margin: 0 auto;
  }

  .f_button {
    width: 100%;
  }

  .thanks-block {
    max-width: 379px;
    margin: 0 auto;

    .img-block {
      margin: 64px 0 70px;
      display: flex;
      justify-content: center;

      img {
        width: 200px;
        height: 200px;
      }
    }
  }
}