// clearfix
@mixin clearfix {
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

// truncate text
@mixin truncate-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// align block in center
@mixin center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// reset list style
@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

// placeholder
@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content} 
}

// RETINA IMAGES
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    background-image: url($image);
    background-size: $width $height;
  }
}
//Usage
// div.logo {
//   background: url("logo.png") no-repeat;
//   @include image-2x("logo2x.png", 100px, 25px);
// }

// WEBFONTS
@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) { 
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + '.eot');
    src: url($file-name + '.eot?#iefix')  format('embedded-opentype'),
         url($file-name + '.woff') format('woff'),
         url($file-name + '.ttf')  format('truetype'),
         url($file-name + '.svg##{$font-name}')  format('svg');
    font-weight: $weight;
    font-style: $style;
  }
 }
 //Usage
 // @include font-face('gotham', '/fonts/gotham');

 // TEXT SHORTENING example "asd(...)"
 @mixin text-shorten {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}
// div {
//    @include text-shorten();
// }