.f_button {
	position: relative;
	display: inline-flex;
	align-items: center;
  justify-content: center;
	background: none;
  //border: 3px solid #C8C9CC;
  border: 3px solid #232C42;

	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: #fff;
  height: 54px;
  transition: all .5s;
  background: #232C42;
  border-radius: 8px;
  overflow: hidden;
  z-index: 0;
  min-width: 250px;

	&:focus,
	&:hover {
    color: #fff;
    border-color: #C8C9CC;
	}

  &:active {
    background: #461193;
    border-color: #9D63F2;
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    background: #A2A6AD;
    border-color: #A2A6AD;
  }

  &.bordered {
    background: none;
    height: 36px;
    min-width: 128px;
    color: #182C4A;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 4px rgba(14, 23, 46, 0.26);
    background: #fff;

    &:focus,
    &:hover {
      color: #442D66;
      box-shadow: 0px 0px 0px 3px #F2F2F2;
    }

    &:active {
      box-shadow: none;
      background: #F0F0F0;
    }
    
    &.disabled,
    &:disabled {
      border: 1px solid #DCDEE0;
      background: #fff;
      color: #DCDEE0;
      box-shadow: none;
    }
  }
}

.f_group {
  position: relative;
  margin-top: 32px;

  @media (max-width: $mobile) {
    margin-top: 24px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.f_label {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 6px;
  font-weight: 500;
  color: #344054;
}

.f_area,
.f_input {
	width: 100%;
	display: block;
  padding: 0 14px;
	font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  height: 44px;
	color: #15171A;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 0px 0px 3px transparent;
  border-radius: 8px;
  transition: all .3s;

  &:focus,
	&:active,
  &:hover {
    box-shadow: 0px 0px 0px 3px #F2F2F2;
  }

	&::-webkit-input-placeholder {
		color: #667085;
	}
	&::-moz-placeholder {
	  color: #667085;
	}
	&:-ms-input-placeholder {
	  color: #667085;
	}
	&:-moz-placeholder {
	  color: #667085;
	}
}

.f_area {
  padding: 10px 14px;
  min-height: 128px;
  resize: none;
}

.error {
  .f_input {
    border-color: #FF3E3E;
    background: url(../img/u-error-icon.svg) calc(100% - 14px) 50% no-repeat;
    padding-right: 32px;
  }

  .error-msg {
    font-size: 14px;
    color: #FF3E3E;
    line-height: 20px;
  }
}

.show-menu {
  .hamburger {
    align-items: center;
    span {
      background: #fff;
    }
    .span-1 {
      transform: rotate(45deg) translate(6px);
    }

    .span-2 {
      width: 21px;
      transform: rotate(495deg) translate(0, -2px);
    }

    .span-3 {
      transform: rotate(45deg);
      opacity: 0;
    }
  }
}

.hamburger {
  display: none;

  @media (max-width: $tablet) {
    display: inline-flex;
    width: 28px;
    height: 28px;
    border: none;
    cursor: pointer;
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    background: none;
    z-index: 1;
    margin-left: 48px;
  }

  @media (max-width: $mobile) {
    margin-left: 40px;
  }

  span {
    width: 21px;
    height: 2px;
    margin-bottom: 4px;
    background: #101828;
    transition: all 0.5s cubic-bezier(0.08, 0.81, 0.87, 0.71);
    border-radius: 100px;

    &.span-1 {
      transform-origin: 5px 0px;
    }

    &.span-3 {
      transform-origin: bottom right;
      margin-bottom: 0;
      width: 16px;
    }
  }
}

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(266.71deg, #443EE6 3.38%, #D74FBA 99.24%);
  border-radius: 80px;
  padding: 0 12px;
  min-height: 32px;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  font-weight: 600;
  letter-spacing: -0.02em;
  margin-bottom: 8px;

  @media (max-width: $mobile) {
    font-size: 12px;
    line-height: 24px;
  }
}