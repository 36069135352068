.footer {
  background: url(../img/u-footer-bg.jpg) 50% 0 no-repeat;
  background-size: auto 100%;
  padding: 24px 0;

  @media (max-width: $mobile) {
    padding: 16px 0;
  }

  .top-block {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    @media (max-width: $mobile) {
      margin-bottom: 16px;
    }

    .logo {
      display: inline-flex;
      background: url(../img/u-logo-f.svg) 0 0 no-repeat;
      width: 130px;
      height: 20px;
      background-size: 100% auto;

      @media (max-width: $mobile) {
        width: 103px;
        height: 16px;
      }
    }

    .logo-flyp {
      display: inline-flex;
      background: url(../img/u-logo-flyp.svg) 0 0 no-repeat;
      width: 56px;
      height: 20px;
      background-size: 100% auto;
      margin-left: 20px;

      @media (max-width: $mobile) {
        width: 46px;
        height: 16px;
        margin-left: 32px;
      }
    }
  }

  .bottom-block {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $mobile) {
      flex-direction: column;
      align-items: flex-start;
    }

    .copyright {
      display: inline-flex;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;

      @media (max-width: $mobile) {
        order: 1;
      }

      &:first-child {
        @media (max-width: $mobile) {
          order: 2;
          margin-top: 16px;
        }
      }
    }
  }
}