* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

a {
  outline: none;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  opacity: 1;

  &:hover {
    outline: none !important;
  }

  &:focus {
    outline: none !important;
  }

}

*:focus {
  outline: none;
}

body {
  position: relative;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-weight: $base-font-weight;
  color: $base-color;
  -webkit-font-smoothing: antialiased;
  background: url(../img/u-bg.jpg) 50% 0 no-repeat;
  background-size: cover;

  @media (max-width: $tablet) {
    background: url(../img/u-bg-tb.jpg) 50% 0 no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
  }

  @media (max-width: $mobile) {
    background: url(../img/u-bg-mb.jpg) 50% 0 no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
  }
}

a {
  color: $base-color;
  text-decoration: none;
  
  &:hover,
  &:focus {
    color: $base-color;
    text-decoration: none;
  }

  &:focus {
    color: #fff;
  }
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
textarea {
  -webkit-appearance: none;
  background-clip: padding-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

//clearfix
.clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}


.container {
  padding-left: 24px;
  padding-right: 24px;
  max-width: calc(1136px + 48px);
  margin: 0 auto;

  @media (max-width: $mobile) {
    padding: 0 16px;
  }
}