.modal {
  position: fixed;
  z-index: 10000;
  /* 1 */
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  overflow-y: auto;
}

.modal.is-visible {
  visibility: visible;
}

.modal-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(52, 64, 84, 0.8);
  backdrop-filter: blur(16px);
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.modal.is-visible .modal-overlay {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.modal-wrapper {
  position: relative;
  z-index: 9999;
  width: -webkit-calc(100% - 16px);
  width: calc(100% - 16px);
  background-color: #fff;
  padding: 32px;
  border-radius: 12px;
  max-width: 432px;
  height: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: $mobile) {
    padding: 24px 16px;
  }
}

.modal-transition {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

.modal.is-visible .modal-transition {
  opacity: 1;
}

.modal-close {
  position: absolute;
  top: 24px;
  right: 24px;
  border: 0;
  mask: url(../img/fs-modal-close.svg) no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: #515B70;
  width: 24px;
  height: 24px;
  padding: 0;
  -webkit-transition: all .3s;
  transition: all .3s;

  @media (max-width: $mobile) {
    top: 16px;
    right: 16px;
  }
}

.modal-close:hover {
  background-color: #461193;
}

.modal-heading {
  display: flex;
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #101828;

  @media (max-width: $mobile) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.01em;
  }
}

.modal-body {
  -webkit-box-flex: 0;
  -webkit-flex: 0 100%;
  -ms-flex: 0 100%;
  flex: 0 100%;
  margin-top: 56px;

  @media (max-width: $mobile) {
    margin-top: 24px;
  }
}

.modal-contact-form {
  .f_group {
    &:last-child {
      margin-top: 56px;

      @media (max-width: $mobile) {
        margin-top: 32px;
      }

      .f_button {
        width: 100%;
      }
    }
  }
}

.modal-thanks {
  padding-top: 120px;
  position: relative;
  text-align: center;
  max-width: 480px;

  @media (max-width: $mobile) {
    padding-top: 104px;
  }

  &:before {
    position: absolute;
    top: 24px;
    left: 50%;
    content: "";
    background: url(../img/fs-thanks-icon.svg) 0 0 no-repeat;
    width: 64px;
    height: 64px;
    margin-left: -32px;

    @media (max-width: $mobile) {
      width: 48px;
      height: 48px;
      margin-left: -24px;
      background-size: 100% auto;
    }
  }

  .modal-body {
    margin-top: 0;
  }

  .modal-heading {
    margin-bottom: 18px;
    justify-content: center;
  }

  p {
    margin: 0 0 40px;

    @media (max-width: $mobile) {
      margin-bottom: 32px;
    }
  }

  .f_button {
    width: 204px;
  }
}
